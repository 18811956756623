import { LoadingPage, NoDataFound } from "@carbonbank/components"
import { useContractsStore } from "@carbonbank/store"
import { Alert, Grid, PageHeader } from "@sustainability/fundamental"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { getContractsUrl } from "../contractsRoutes"
import {
	DataExtraction,
	Deliveries,
	PageHeaderButtons,
	CarbonSavings,
	DeclaredProduct,
	DnvAssurance,
	BundledDeliveredProduct,
} from "./components"

export const ContractDetails: React.FC = () => {
	const [fetchContractDetails, contract, isLoading, error] =
		useContractsStore(state => [
			state.fetchContractDetails,
			state.contract,
			state.isLoading,
			state.error,
		])

	const { id } = useParams()

	const { t } = useTranslation("cb", {
		keyPrefix: "common",
	})
	const { t: tCommon } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.contractDetailsHeader",
	})

	useEffect(() => {
		if (id !== undefined) {
			fetchContractDetails(id)
		}
	}, [fetchContractDetails, id])

	if (isLoading) {
		return (
			<LoadingPage
				backToPageUrl={getContractsUrl()}
				backToPageLabel={tCommon("backToContractsOverview")}
				title={tCommon("contract")}
			/>
		)
	}
	if (error) {
		return (
			<NoDataFound
				title={tCommon("contractNotFound")}
				description={tCommon("description")}
				buttonTtitle={tCommon("backToOverView")}
				backToPageUrl={getContractsUrl()}
			/>
		)
	}

	return (
		<main
			data-cy="contract-details"
			className="flex flex-col gap-6 px-24px mb-4"
		>
			<PageHeader
				backToPageUrl={getContractsUrl()}
				backToPageLabel={tCommon("backToContractsOverview")}
				data-cy="contract-details-header"
				wrapAside
				aside={<PageHeaderButtons />}
				asideClassname="w-full"
			>
				{tCommon("contract")} {contract?.contractNumber}
			</PageHeader>
			<DataExtraction />
			<Alert data-cy="contract-details-alert" variant="success">
				{t("contractDetailsAlert")}
			</Alert>
			<Grid
				size={1}
				lgSize={2}
				className="px-0px"
				data-cy="transaction-details-data"
			>
				<DeclaredProduct
					data-cy="contract-details-declaredproduct"
					data={contract}
				/>
				<BundledDeliveredProduct
					data-cy="contract-details-bundled"
					data={contract}
				/>
			</Grid>
			<CarbonSavings data={contract} />
			<DnvAssurance data={contract} />
			<Deliveries />
		</main>
	)
}
