import { LoadingPage, NoDataFound } from "@carbonbank/components"
import { TransactionHistoryCardList } from "@carbonbank/pages/transactions/TransactionDetails/components"
import { useMicrositeStore } from "@carbonbank/store"
import { transactionIdFormatter } from "@carbonbank/utils"
import { Grid, H1 } from "@sustainability/fundamental"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { MicroSiteLogo, MicroSiteMainImage } from "../assets"
import { CarbonCaptureAssurance, MicroSiteDecription } from "../components"

export const MicrositePage: React.FC = () => {
	const { t } = useTranslation("microsite", {
		keyPrefix: "microsite.tracebility",
	})
	const { t: tCommon } = useTranslation("microsite", {
		keyPrefix: "microsite.descption",
	})
	const [fetchMicrositeData, certificate, isLoading, error] =
		useMicrositeStore(state => [
			state.fetchMicrositeData,
			state.certificate,
			state.isLoading,
			state.error,
		])

	const { id } = useParams()
	const formattedId = id ? transactionIdFormatter(id) : ""
	useEffect(() => {
		if (formattedId !== undefined) {
			fetchMicrositeData(formattedId)
		}
	}, [fetchMicrositeData, id])
	if (isLoading) {
		return <LoadingPage />
	}
	if (error) {
		return (
			<NoDataFound
				title={tCommon("noTransaction")}
				description={tCommon("noTransactionDescription")}
				buttonTtitle={tCommon("goBack")}
				backToPageUrl="/"
			/>
		)
	}
	return (
		<main
			className="flex flex-col gap-10 p-10 min-h-screen"
			data-cy="microite-page"
		>
			<img
				src={MicroSiteLogo}
				className="w-[191px] h-[26px]"
				alt="micrositeLogo"
				data-cy="microsite-logo"
			/>
			<img
				src={MicroSiteMainImage}
				className="w-full h-[500px] "
				alt="micrositeLogo"
				data-cy="microsite-main-image"
			/>
			<MicroSiteDecription certificate={certificate} />
			<Grid
				size={1}
				className="w-1/2 pr-12"
				data-cy="microsite-traceability"
			>
				<H1 className="text-foreground_faded">{t("title")}</H1>
				<p>{t("description")}</p>
			</Grid>
			<TransactionHistoryCardList steps={certificate?.steps ?? []} />
			<CarbonCaptureAssurance />
		</main>
	)
}
