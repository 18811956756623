﻿import {
	CementManufacturingImage,
	ConcreteProductImage,
	DnvAssuranceTransactionImage,
	FinalOwnerImage,
	PermanentStorageImage,
	ReceivingTerminalImage,
	TemporaryStorageImage,
	TransportPreparationImage,
	TransportViaShipImage,
} from "@carbonbank/assets"
import { CertificateSectionHeader } from "@carbonbank/pdf/certificates/transfer/components/transferPdfCommons.tsx"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { Image, NodeProps, Text, View } from "@react-pdf/renderer"

export const TransferTraceabilitySection = (
	props: {
		steps: {
			title: string
			duration: string
		}[]
	} & NodeProps,
) => {
	return (
		<View style={{ ...styles.gap_10 }}>
			<CertificateSectionHeader content="Traceability of carbon savings" />
			<Text style={{ ...styles.text_regular }}>
				Each step in the carbon saving process is meticulously tracked.
				To maintain transparency and accountability, a unique
				transaction ID is assigned to each step. Please note that the
				time periods of the recorded data may overlap across the steps
				as the storage terminal receives a new shipment of captured
				carbon every fourth day and a allocation of carbon savings to a
				customer delivery can include multiple carbon savings shipments.
			</Text>
			{props.steps.map(step => {
				const stepInfo = getTextualExplanationsByEventName(step.title)

				return (
					<View
						key={`${step.title}-${stepInfo.id}`}
						style={{
							...styles.flex_row,
							...styles.justify_space_between,
							...styles.gap_20,
						}}
					>
						<View
							style={{
								...styles.border_underline,
								...styles.width_92px,
								...styles.height_86px,
							}}
						>
							<Image src={stepInfo.image} />
						</View>
						<View
							style={{
								...styles.border_underline,
								...styles.gap_10,
								...styles.flex_1,
							}}
						>
							<View
								style={{ ...styles.flex_row, ...styles.gap_10 }}
							>
								<Text
									style={{
										...styles.text_regular_bold,
										...styles.text_color_grey,
									}}
								>
									Step {stepInfo.id}
								</Text>
								<Text style={{ ...styles.text_regular_bold }}>
									{stepInfo.title}
								</Text>
							</View>
							<Text style={{ ...styles.text_regular }}>
								{stepInfo.description}
							</Text>
							<View
								style={{ ...styles.flex_row, ...styles.gap_10 }}
							>
								<Text style={{ ...styles.text_regular }}>
									Date
								</Text>
								<Text style={{ ...styles.text_regular_bold }}>
									{step.duration}
								</Text>
							</View>
						</View>
					</View>
				)
			})}
		</View>
	)
}

const getTextualExplanationsByEventName = (eventName: string) => {
	switch (eventName) {
		case "co2_capture_produced":
			return {
				id: 1,
				title: "Cement manufacturing and carbon capture",
				description:
					"The carbon is captured during the clinker process via amine-based technology",
				image: CementManufacturingImage,
			}
		case "co2_capture_stored":
			return {
				id: 2,
				title: "Temporary storage on the plant",
				description:
					"Liquefaction of captured carbon and intermediate onsite storage",
				image: TemporaryStorageImage,
			}
		case "co2_capture_delivered":
			return {
				id: 3,
				title: "Preparation for transport",
				description:
					"The carbon is pumped from the plant’s silos to the ship, operated by Northern Lights",
				image: TransportPreparationImage,
			}
		case "co2_storage_received":
			return {
				id: 4,
				title: "Carbon transport via ship",
				description:
					"Purpose-built ships transport the liquid carbon at -26°C to Øygården for permanent storage.",
				image: TransportViaShipImage,
			}
		case "co2_storage_shipped":
			return {
				id: 5,
				title: "Receiving terminal",
				description:
					"Upon arrival in Øygården, the liquid carbon is pumped into the pipeline.",
				image: ReceivingTerminalImage,
			}
		case "co2_storage_stored":
			return {
				id: 6,
				title: "Permanent carbon storage",
				description:
					"The carbon is captured during the clinker process via amine-based technology",
				image: PermanentStorageImage,
			}
		case "deposited":
			return {
				id: 7,
				title: "Assurance by DNV, allowing a carbon bank deposit",
				description:
					"Once the 3rd party assurance body ‘DNV’ has assured the stored captured carbon volume in a digital Monitor-Report-Verify system, a new deposit is made in the carbon bank. ",
				image: DnvAssuranceTransactionImage,
			}
		case "withdraw":
			return {
				id: 8,
				title: "evoZero delivery to [RMC Producer], triggering a carbon bank withdrawal",
				description:
					"evoZero delivery to [RMC Producer], triggering a carbon bank withdrawal",
				image: ConcreteProductImage,
			}
		case "transferred":
			return {
				id: 9,
				title: "Transfer of carbon savings to [Customer]",
				description:
					"The concrete producer initiated the transfer of the carbon savings to [Customer], based on the evoZero cement volume that went in to the delivered concrete mix.",
				image: FinalOwnerImage,
			}
		default:
			throw Error(`event name ${eventName} is not valid`)
	}
}
