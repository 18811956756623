﻿import { StyleSheet } from "@react-pdf/renderer"

// todo load fonts
export const styles = StyleSheet.create({
	text_color_dark: {
		color: "#3b3b3b",
	},
	text_size_12px: {
		fontSize: "12px",
	},
	text_lexend: {
		fontFamily: "Lexend",
	},
	text_font_weigh_700: {
		fontWeight: 700,
	},
	padding_10: {
		padding: 10,
	},
	page_info: {
		gap: 30,
		padding: 50,
	},
	page: {
		width: "100%",
		maxHeight: "100vh",
		padding: 0,
	},
	position_absolute: {
		position: "absolute",
		left: "0px",
		right: "0px",
		marginHorizontal: "auto",
	},
	background_image: {
		width: "100vw",
		height: "100vh",
	},
	first_page_content: {
		padding: "50px",
		gap: "50px",
		width: "528px",
		borderBottomRightRadius: 120,
		backgroundColor: "#ffffff",
	},
	first_page_hc_logo: {
		position: "relative",
		left: 50,
		top: 300,
	},
	text_regular: {
		fontWeight: 400,
		fontSize: "12px",
		color: "#3b3b3b",
		fontFamily: "Lexend",
	},
	text_bold_big: {
		fontWeight: 700,
		fontSize: "16px",
		color: "#3b3b3b",
		fontFamily: "Lexend",
	},
	text_regular_bold: {
		fontWeight: 700,
		fontSize: "12px",
		color: "#3b3b3b",
		fontFamily: "Lexend",
	},
	text_regular_small: {
		fontWeight: 400,
		fontSize: "11px",
		color: "#3b3b3b",
		fontFamily: "Lexend",
	},
	page_header: {
		flexDirection: "row-reverse",
		gap: 30,
	},
	text_color_grey: {
		color: "#767676",
	},
	text_section_header: {
		fontWeight: 700,
		fontSize: "26px",
		fontFamily: "Lexend",
	},
	gap_2: {
		gap: 2,
	},
	gap_10: {
		gap: 10,
	},
	gap_100: {
		gap: 100,
	},
	gap_20: {
		gap: 20,
	},
	flex_row: {
		flexDirection: "row",
	},
	justify_space_between: {
		justifyContent: "space-between",
	},
	border_underline: {
		borderColor: "#3b3b3b",
		borderBottomWidth: 1,
	},
	padding_10_20_10: {
		padding: "10px 20px 10px 0px",
	},
	text_right: {
		textAlign: "right", // todo not working
	},
	max_wight_315: {
		maxWidth: 315,
	},
	flex_1: {
		flex: 1,
	},
	width_15: {
		width: 15,
	},
	flex_row_reverse: {
		flexDirection: "row-reverse",
	},
	width_100px: {
		width: 100,
	},
	height_100px: {
		height: 100,
	},
	background_purple: {
		backgroundColor: "#ff00ff",
	},
	max_width_600px: {
		maxWidth: 600,
	},
	background_grey: {
		backgroundColor: "#e6eaed",
	},
	justify_space_around: {
		justifyContent: "space-around",
	},
	width_92px: {
		width: 92,
	},
	height_86px: {
		height: 86,
	},
	height_92px: {
		height: 92,
	},
	width_336px: {
		width: 336,
	},
	width_64px: {
		width: 64,
	},
	height_64px: {
		height: 64,
	},
})
