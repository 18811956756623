import { LoadingPage } from "@carbonbank/components"
import { useCustomerStore } from "@carbonbank/store"
import { Yup } from "@carbonbank/yup"
import {
	CanvasCard,
	PageHeader,
	spawnSuccessToast,
} from "@sustainability/fundamental"
import { Form, Formik } from "formik"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { CertificateInformation } from "../components"
import { PageHeaderButtons } from "../components/PageHeaderButtons"
import { ProjectInformationForm } from "../components/ProjectInformationForm"
import { CustomerKeys, ProjectInformationInput } from "../types"

const useTypedTranslation = () => {
	const { t } = useTranslation("customer", {
		keyPrefix: "customer",
	})
	return (key: CustomerKeys) => t(key)
}
export const CustomerPage: React.FC = () => {
	const t = useTypedTranslation()
	const [certificates, fetchCertificatesInformation, submitData, isLoading] =
		useCustomerStore(state => [
			state.certificates,
			state.fetchCertificatesInformation,
			state.submitData,
			state.isLoading,
		])

	const validationSchema = Yup.object().shape({
		projectTitle: Yup.string()
			.required(t("projectInformation.projectTitleRequired"))
			.min(5, t("projectInformation.projecctTitleMaxLength")),
		description: Yup.string().max(
			400,
			t("projectInformation.descriptionMaxLength"),
		),
		city: Yup.string().required(t("projectInformation.cityRequired")),
		country: Yup.string().required(t("projectInformation.countryRequired")),
		completion: Yup.string().required(
			t("projectInformation.completionRequired"),
		),
		published: Yup.boolean(),
	})

	const handleSubmit = async (values: ProjectInformationInput) => {
		await submitData(values, values.image as File)
		spawnSuccessToast(t("customerToastFlagged"), {
			"data-cy": "toast-success-customer",
		})
	}

	const { id } = useParams()
	const formattedId = id?.split("-").join(".") as string
	useEffect(() => {
		fetchCertificatesInformation(formattedId)
	}, [fetchCertificatesInformation, formattedId])

	const initialValues: ProjectInformationInput = {
		projectId: certificates?.buildingDetails.projectId || "",
		projectTitle: certificates?.buildingDetails.projectTitle || "",
		description: certificates?.buildingDetails.description || "",
		city: certificates?.recipientDetails.city || "",
		country: certificates?.recipientDetails.country || "",
		completion: certificates?.buildingDetails.completion || "",
		image: null,
		published: certificates?.buildingDetails.published || false,
	}
	if (isLoading) {
		return <LoadingPage />
	}

	return (
		<main
			data-cy="customer-page"
			className="flex flex-col gap-4 min-h-screen"
		>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{({ isValid }) => {
					return (
						<Form>
							<PageHeader
								backToPageUrl="/"
								backToPageLabel={t("backToBuildingProject")}
								data-cy="customer-page-header"
								aside={<PageHeaderButtons isValid={isValid} />}
								className="mx-4"
							>
								{t("title")}
							</PageHeader>
							<CanvasCard
								data-cy="customer-page-card"
								className="flex-grow"
							>
								<CertificateInformation data={certificates} />
								<ProjectInformationForm />
							</CanvasCard>
						</Form>
					)
				}}
			</Formik>
		</main>
	)
}
