﻿import { CarbonCaptureAttributesDescription } from "@carbonbank/pdf/certificates/transfer/components/CarbonCaptureAttributesDescription.tsx"
import {
	CertificateSectionHeader,
	HorizontalSectionItem,
} from "@carbonbank/pdf/certificates/transfer/components/transferPdfCommons.tsx"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { formatNumber } from "@carbonbank/utils"
import { NodeProps, View } from "@react-pdf/renderer"

export const CarbonCaptureAttributes = (
	props: {
		gwpGross: number
		fossilAmount: number
		bioAmount: number
		declaredProduct: string
		gwpCarbonCaptured: number
	} & NodeProps,
) => {
	return (
		<View style={{ ...styles.gap_10 }}>
			<CertificateSectionHeader content="Carbon Captured attributes" />
			<HorizontalSectionItem
				title="GWP Total (gross) of delivered product (kg CO2-eq)"
				content={formatNumber(props.gwpGross)}
			/>
			<HorizontalSectionItem
				title="evoZero carbon savings of evoZero Carbon Savings declared product (kg CO2-eq)"
				content={formatNumber(props.bioAmount + props.fossilAmount)}
			/>
			<HorizontalSectionItem
				title={`GWP - ${props.declaredProduct} carbon captured (kg C02-eq)`}
				content={formatNumber(props.gwpCarbonCaptured)}
				underline={false}
			/>
			<CarbonCaptureAttributesDescription
				bioAmount={props.bioAmount}
				fossilAmount={props.fossilAmount}
			/>
		</View>
	)
}
