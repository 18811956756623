import { CarbonCaptureStepView } from "@carbonbank/api"
import { Grid } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

import { TransactionHistoryCard } from "./TransactionHistoryCard"
import { TransactionHistoryCardListConstant } from "./TransactionHistoryConstants"

interface TransactionHistoryCardListProps {
	steps: CarbonCaptureStepView[]
}
export const TransactionHistoryCardList: React.FC<
	TransactionHistoryCardListProps
> = ({ steps }) => {
	const { t } = useTranslation("transactions")
	const transactionHistoryItems = steps.map((step, index) => {
		const historyCard = TransactionHistoryCardListConstant[index]

		return {
			id: index + 1,
			title: t(historyCard.title),
			description: t(historyCard.description),
			startDate: step.startDate,
			endDate: step.endDate,
			image: historyCard.image,
			proofDetails: step.proof.identifier,
		}
	})

	return (
		<Grid
			size={1}
			lgSize={4}
			mdSize={2}
			className="px-0"
			data-cy="transaction-history-list"
		>
			{transactionHistoryItems.map(transaction => (
				<TransactionHistoryCard
					key={transaction.id}
					title={transaction.title}
					description={transaction.description}
					startDate={transaction.startDate}
					endDate={transaction.endDate}
					image={transaction.image}
					proofId={transaction.proofDetails}
				/>
			))}
		</Grid>
	)
}
