import { ContainerBox, LoadingPage } from "@carbonbank/components"
import { useFilterStore } from "@carbonbank/store"
import { ProductAllocationFilterInput } from "@carbonbank/store/types"
import { useProductAllocationStore } from "@carbonbank/store/useProductAllocationStore"
import { formatNumber } from "@carbonbank/utils"
import {
	PageHeader,
	Button,
	Kpi,
	CardHeader,
	FilterForm,
	CanvasCard,
	icon,
	FilterResult,
	FilterConfig,
} from "@sustainability/fundamental"
import {
	FieldFormatOptions,
	useFilter,
} from "@sustainability/fundamental-hooks"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { TransactionsTable } from "./components/TransactionsTable"
import { productAllocationFilterConfig } from "./productAllocationFilter.config"
import { downloadCsv } from "./utils"

interface ProductAllocationFilterProps {
	site: string
	customer: string
	product: string
	timeframe: [Date, Date]
	flagged: "all" | "flagged"
}

export const DnvProductAllocation: React.FC = () => {
	const { t } = useTranslation("cs", {
		keyPrefix: "productAllocation.page",
	})
	const { t: tTable } = useTranslation("cs", {
		keyPrefix: "productAllocation.components.transactionsTable",
	})
	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	const DEFAULT_TIMEFRAME = useMemo(
		() => [moment().startOf("year").toDate(), new Date()] as [Date, Date],
		[],
	)

	const initialFilterValue: ProductAllocationFilterProps = useMemo(
		() => ({
			site: "",
			customer: "",
			product: "",
			timeframe: DEFAULT_TIMEFRAME,
			flagged: "all",
		}),
		[DEFAULT_TIMEFRAME],
	)

	const [isLoading, setLoading] = useState(false)

	const [allocationFilterData, fetchAllocationFilterData] = useFilterStore(
		state => [state.allocationFilterData, state.fetchAllocationFilterData],
	)

	const [fetchInvoiceList, resetStore, invoiceList] =
		useProductAllocationStore(state => [
			state.fetchInvoiceList,
			state.resetStore,
			state.invoiceList,
		])

	const fetchTransactions = useCallback(
		async (filters: ProductAllocationFilterInput) => {
			setLoading(true)
			await fetchInvoiceList(filters)
			setLoading(false)
		},
		[fetchInvoiceList],
	)

	const handleFilterUpdated = useCallback(
		(updatedFilter?: ProductAllocationFilterProps) => {
			if (!updatedFilter) {
				return
			}

			fetchTransactions({
				customer: updatedFilter.customer,
				flagged: updatedFilter.flagged,
				product: updatedFilter.product,
				location: updatedFilter.site,
				timeframe: updatedFilter.timeframe.map(date =>
					new Date(date).toISOString(),
				),
			})
		},
		[fetchTransactions],
	)

	const [filter, setFilter] = useFilter<ProductAllocationFilterProps>(
		initialFilterValue,
		handleFilterUpdated,
		{
			fieldFormatOptions: [
				{
					field: "timeframe",
					display: "timeframe",
					format: (value: [Date, Date]) =>
						value.map(date => date.toISOString()).join(","),
					parse: (value: string) => {
						const timeframe = value.split(",")
						return timeframe.map(date => new Date(date)) as [
							Date,
							Date,
						]
					},
				} as FieldFormatOptions<
					ProductAllocationFilterProps,
					keyof ProductAllocationFilterProps
				>,
			],
		},
	)

	useEffect(() => {
		resetStore()
		fetchAllocationFilterData()
	}, [fetchAllocationFilterData, resetStore])

	useEffect(() => {
		const shouldSetFilter =
			filter &&
			!filter.customer &&
			!filter.product &&
			!filter.site &&
			allocationFilterData &&
			allocationFilterData.siteFilterData.length > 0 &&
			allocationFilterData.siteFilterData[0].siteName &&
			allocationFilterData.customerFilterData.length > 0 &&
			allocationFilterData.customerFilterData[0].customerNumber &&
			allocationFilterData.productFilterData.length > 0 &&
			allocationFilterData.productFilterData[0].productName

		if (shouldSetFilter) {
			setFilter({
				site: allocationFilterData.siteFilterData[0].siteName!,
				customer:
					allocationFilterData.customerFilterData[0].customerNumber!,
				product: allocationFilterData.productFilterData[0].productName!,
				timeframe: filter.timeframe,
				flagged: filter.flagged,
			})
		}
	}, [allocationFilterData, filter, setFilter])

	const totalCarbonSaving = useMemo(
		() =>
			invoiceList?.reduce(
				(acc, { allocatedSavings }) => acc + allocatedSavings,
				0,
			) ?? 0,
		[invoiceList],
	)

	const onDownloadCsv = () => {
		const headers = [
			"timestamp",
			"customer",
			"product",
			"quantity",
			"productEmissions",
			"allocatedCarbonSavings",
		] as const
		const translatedHeaders = headers.map(header => tTable(header))
		const transactionsStringifiedData = invoiceList?.map(invoice => [
			invoice.timestamp,
			invoice.customer,
			invoice.product,
			`${invoice.quantity.amount} ${invoice.quantity.unit}`,
			invoice.productEmissions,
			invoice.allocatedSavings,
		])

		if (!transactionsStringifiedData) {
			return
		}

		downloadCsv("transactions-list.csv", [
			translatedHeaders,
			...transactionsStringifiedData,
		])
	}

	const allocationSavingsUnit = tUnits("kgCO2")

	const onUpdateFilter = useCallback(
		(data: FilterResult<FilterConfig[]>) => {
			const newFilterStat =
				data as unknown as ProductAllocationFilterProps
			setFilter(newFilterStat)
		},
		[setFilter],
	)

	if (!filter || !allocationFilterData || !invoiceList || isLoading) {
		return <LoadingPage title={t("title")} />
	}

	return (
		<>
			<ContainerBox>
				<PageHeader data-cy="page-title">{t("title")}</PageHeader>
				<FilterForm
					data-cy="filter-form"
					initialValues={{ ...filter }}
					config={productAllocationFilterConfig(
						allocationFilterData,
						t,
					)}
					onChange={onUpdateFilter}
				/>
			</ContainerBox>
			<CanvasCard className="flex-grow">
				<CardHeader
					data-cy="transactions-header"
					aside={
						<div className="flex flex-row gap-x-6">
							<Kpi
								data-cy="total-savings"
								label={t("totalSavingsLabel")}
								value={formatNumber(totalCarbonSaving)}
								size="large"
								unit={allocationSavingsUnit}
							/>
							{invoiceList?.length > 0 && (
								<Button
									data-cy="download-csv"
									variant="secondary"
									icon={icon.mdiDownloadOutline}
									onClick={onDownloadCsv}
								>
									{t("downloadCsv")}
								</Button>
							)}
						</div>
					}
				>
					{t("transactions", { count: invoiceList.length })}
				</CardHeader>

				<TransactionsTable data={invoiceList ?? []} />
			</CanvasCard>
		</>
	)
}
