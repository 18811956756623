import { Contract } from "@carbonbank/api"
import { LogoDnv } from "@carbonbank/assets"
import { DnvAssuranceModal } from "@carbonbank/components"
import {
	Button,
	CardHeader,
	DashboardCard,
	Label,
	TextLong,
	icon,
} from "@sustainability/fundamental"
import { useState } from "react"
import { useTranslation } from "react-i18next"

interface DnvAssuranceProps {
	data?: Contract
}
export const DnvAssurance: React.FC<DnvAssuranceProps> = ({
	data,
}: DnvAssuranceProps) => {
	const [openDnv, setOpenDnv] = useState(false)
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.dnvAssurance",
	})

	return (
		<DashboardCard data-cy="contract-details-dnvasurance">
			<CardHeader
				aside={
					<Button
						className="text-wrap"
						variant="tertiary"
						icon={icon.mdiInformationOutline}
						onClick={() => setOpenDnv(true)}
						data-cy="dnv_modal_button"
					>
						{t("ensuringThirdParty")}
					</Button>
				}
				wrapAside
				className="!text-wrap"
			>
				{t("title")}
			</CardHeader>
			<DnvAssuranceModal
				openDnvInfo={openDnv}
				setOpenDnvInfo={setOpenDnv}
			/>
			<div className="flex gap-3">
				<img
					src={LogoDnv}
					alt="dnvassurance"
					className="w-[52px] h-[28px]"
				/>
				<Label title={t("assuranceTimeStamp")}>
					{data?.assuranceTimestamps?.join(" | ")}
				</Label>
			</div>
			<div className="mt-8">
				<TextLong className="text-foreground_faded">
					{t("dnvInfo")}
				</TextLong>
			</div>
		</DashboardCard>
	)
}
