import { carbonBankWithAuthClient } from "../client"
import { generateGetUrl } from "../utils"
import {
	TransferValidationForm,
	Transfer,
	TransferSearchParams,
	TransferCertificate,
	CreateTransferForm,
} from "./types"

export const transferApi = {
	getTransfers: (params?: TransferSearchParams): Promise<Transfer[]> =>
		carbonBankWithAuthClient
			.get("/transfers/all", { params })
			.then(response => response.data),
	getTransferCertificate: (
		transactionId: string,
	): Promise<TransferCertificate> =>
		carbonBankWithAuthClient
			.get(`/transfers/${transactionId}/certificate`)
			.then(response => response.data),
	downloadEpd: (transferId: string): Promise<BlobPart> =>
		carbonBankWithAuthClient
			.get(generateGetUrl("/transfers/download-epd", { transferId }), {
				responseType: "blob",
			})
			.then(response => response.data),
	createTransfer: (
		transfer: CreateTransferForm,
		epdFile: File[],
	): Promise<void> => {
		const formData = new FormData()
		formData.append("transferDtoJson", JSON.stringify(transfer))

		epdFile.forEach(file => {
			formData.append("epdFile", file)
		})

		return carbonBankWithAuthClient.post("/transfers", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
	},
	updateStatusCertificate: (
		validationIputs: TransferValidationForm,
	): Promise<void> =>
		carbonBankWithAuthClient.post(
			"/transfers/update-status",
			validationIputs,
		),
}
