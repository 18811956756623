﻿import { carbonBankUrl } from "@carbonbank/api"
import { SectionItem } from "@carbonbank/pdf/certificates/transfer/components/transferPdfCommons.tsx"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { formatNumber } from "@carbonbank/utils"
import { Image, NodeProps, Text, View } from "@react-pdf/renderer"
import React from "react"

export const AssuranceInfoSection = (
	props: {
		assuranceTransactionId: string
		assuranceIssued: string
		approver: string
		customer: string
		carbonSavings: number
		plant: string
		firstDate: string
		lastDate: string
	} & NodeProps,
) => {
	return (
		<View style={{ ...styles.gap_10 }}>
			<View
				style={{ ...styles.flex_row, ...styles.justify_space_between }}
			>
				<SectionItem
					title="Assurance Transaction Id"
					value={props.assuranceTransactionId}
				/>
				<SectionItem title="Issued" value={props.assuranceIssued} />
				<SectionItem title="Approver" value={props.approver} />
			</View>
			<View style={{ ...styles.flex_row_reverse, ...styles.gap_10 }}>
				<View
					style={{
						...styles.width_100px,
						...styles.height_100px,
					}}
				>
					<Image src={`${carbonBankUrl}/certificates/qr-code`} />
				</View>
				<View style={{ ...styles.gap_10, ...styles.max_width_600px }}>
					<Text style={{ ...styles.text_regular }}>
						This certificate is the proof of assurance that{" "}
						{formatNumber(props.carbonSavings)} kg CO2 is captured
						at Heidelberg Materials plant in {props.plant} within{" "}
						{props.firstDate} to
						{props.lastDate} and only issued to {props.customer}{" "}
						without any double counting.
					</Text>
					<View>
						<Text style={{ ...styles.text_regular }}>
							Learn more about the scope of assurance on:
						</Text>
						<Text
							style={{
								...styles.text_regular_bold,
								...styles.text_color_grey,
							}}
						>
							https://www.heidelbergmaterials.com/en/products-and-services/sustainable
							products/DNV_Assurance
						</Text>
					</View>
					<Text style={{ ...styles.text_regular }}>
						This certificate enables (customer) to use evoZero
						carbon captured CO2 savings in their ESG carbon
						reporting. The assurance is conducted by [DNV Business
						Assurance Gmbh] through yearly auditing the data
						involved in the entire process - including deposit of
						CO2 savings originating from CCS plant in a
						decentralized system and withdrawels of these savings to
						be bundled with the products. The audit was done against
						the criteria listed in Heidelberg Materials methodology
						independently reviewed by DNV.
					</Text>
					<View>
						<Text style={{ ...styles.text_regular }}>
							Learn more about the methodology on:
						</Text>
						<Text
							style={{
								...styles.text_regular_bold,
								...styles.text_color_grey,
							}}
						>
							https://www.heidelbergmaterials.com/en/products-and-services/sustainable-products/Methodology
						</Text>
					</View>
				</View>
			</View>
		</View>
	)
}
