import { initChartJs } from "@carbonbank/initChartJs"
import { useAuthStore } from "@carbonbank/store"
import {
	AnalyticsShell,
	NOT_AVAILABLE,
} from "@sustainability/analytics-frontend"
import {
	Navigation,
	NavigationLogo,
	LogoCarbonBank,
	NavigationTabsProps,
	DropdownItem,
	DropdownWithButton,
	ButtonComponentProps,
	Button,
	icon,
} from "@sustainability/fundamental"
import React, { useLayoutEffect, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"

interface PageLayoutProps {
	productName: string
	navigation: NavigationTabsProps[]
	children?: React.ReactNode
}

export const PageLayout: React.FC<PageLayoutProps> = ({
	children,
	navigation,
	productName,
}) => {
	useLayoutEffect(() => {
		initChartJs()
	}, [])

	const { i18n } = useTranslation()

	const [loginState, logout, login] = useAuthStore(store => [
		store.loginState,
		store.logout,
		store.login,
	])
	const location = useLocation()

	const navigationTabs = navigation.map(t => ({
		...t,
		active: location.pathname === t.url,
	}))
	const UserProfileDropdownButton = forwardRef<
		HTMLButtonElement,
		ButtonComponentProps
	>(function UserProfileDropdownButton({ onClick }, ref) {
		return (
			<Button
				variant="secondary"
				icon={icon.mdiAccount}
				round
				square
				onClick={onClick}
				ref={ref}
			/>
		)
	})

	const UserProfileDropdown = (
		<DropdownWithButton
			key="userProfileDropdown"
			label=""
			component={UserProfileDropdownButton}
			align="right"
		>
			{loginState.loggedIn ? (
				<DropdownItem key="Logout" title="Logout" onClick={logout} />
			) : (
				<DropdownItem key="Login" title="Login" onClick={login} />
			)}
		</DropdownWithButton>
	)

	return (
		<div className="bg-page_background">
			<div className="w-full max-w-[1920px] min-h-screen mx-auto flex flex-col">
				<Navigation
					rightSideButtons={[UserProfileDropdown]}
					rightSideButtonsMobile={[UserProfileDropdown]}
					navigationTabs={navigationTabs}
					data-cy="navigation-component"
				>
					<Link to="/">
						<NavigationLogo
							image={LogoCarbonBank}
							alt={"Logo Carbon Bank"}
						>
							{productName}
						</NavigationLogo>
					</Link>
				</Navigation>
				<AnalyticsShell
					configuration={{
						appName: import.meta.env.VITE_APP_NAME,
						appEnvironment: import.meta.env.MODE,
						trackPageChanges: true,
						locationKey: location.pathname + location.search,
						allowTracking: true,
						anonymizeSensitiveData: true,
						connectionString: import.meta.env
							.VITE_ANALYTICS_CONNECTION_STRING,
						includeEventTimestamp: true,
						maxBatchInterval: 10000,
					}}
					eventProperties={{
						product: import.meta.env.VITE_APP_NAME,
						userId: loginState.loggedIn
							? loginState.decodedToken?.user_id
							: NOT_AVAILABLE,
						appVersion: import.meta.env.VITE_APP_VERSION,
						resolvedLanguage:
							i18n.resolvedLanguage ?? NOT_AVAILABLE,
					}}
				>
					{children}
				</AnalyticsShell>
			</div>
		</div>
	)
}
