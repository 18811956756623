import { GetCommentsRequest, CommentStatus, CommentType } from "@carbonbank/api"
import { ContainerBox, LoadingPage } from "@carbonbank/components"
import { useAuthStore, useCommentsStore } from "@carbonbank/store"
import { useProductAllocationStore } from "@carbonbank/store/useProductAllocationStore"
import {
	formatNumberWithUnit,
	formatTimestamp,
	UnitType,
} from "@carbonbank/utils"
import {
	PageHeader,
	Grid,
	DashboardCard,
	CardHeader,
	Label,
	useModal,
	spawnSuccessToast,
	FormFields,
} from "@sustainability/fundamental"
import { FormikHelpers } from "formik"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { getProductAllocationUrl } from "../carbonSavingsRoutes"
import {
	PageHeaderButtons,
	CommentBoxCard,
	FlagAllocationModal,
} from "./components"

export const DnvProductAllocationDetails: React.FC = () => {
	const { id } = useParams()
	const navigate = useNavigate()

	const { t } = useTranslation("cs", {
		keyPrefix: "productAllocationDetails.page",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.fuel",
	})

	const [
		invoiceCertificate,
		fetchInvoiceCertificate,
		flagInvoice,
		resolveInvoice,
		resetStore,
	] = useProductAllocationStore(state => [
		state.invoiceCertificate,
		state.fetchInvoiceCertificate,
		state.flagInvoice,
		state.resolveInvoice,
		state.resetStore,
	])

	const [comments, fetchComments, submitComment, defineCommentsType] =
		useCommentsStore(state => [
			state.comments,
			state.fetchComments,
			state.submitComment,
			state.defineCommentsType,
		])

	const [decodedToken] = useAuthStore(state => [state.decodedToken])

	const { openModal, isOpen, closeModal } = useModal()

	const commentsSearchParams: GetCommentsRequest = {
		relatedEntityId: id ?? "",
		type: CommentType.ProductAllocation,
	}

	useEffect(() => {
		if (!id) {
			return navigate("/")
		}

		resetStore()
		fetchInvoiceCertificate(id)
		fetchComments(commentsSearchParams)
		defineCommentsType(CommentType.ProductAllocation)
	}, [id])

	if (!invoiceCertificate) {
		return (
			<LoadingPage
				backToPageUrl={getProductAllocationUrl()}
				backToPageLabel={t("backToPageLabel")}
				title={t("title")}
			/>
		)
	}

	const isFlaggedFlow = !invoiceCertificate.isFlagged
	const handleFlagAllocation: React.ComponentProps<
		typeof FlagAllocationModal
	>["onFormSubmit"] = async ({ comment }, { setSubmitting, resetForm }) => {
		const toggleFlagPromise: Promise<void> = isFlaggedFlow
			? flagInvoice(id!, comment)
			: resolveInvoice(id!)

		await Promise.all([
			toggleFlagPromise,
			submitComment({
				username: decodedToken?.name ?? "",
				relatedEntityId: id!,
				status: isFlaggedFlow
					? CommentStatus.Flagged
					: CommentStatus.Resolved,
				type: CommentType.ProductAllocation,
				content: comment,
			}),
		])
		spawnSuccessToast(
			isFlaggedFlow ? t("toastFlagged") : t("toastResolved"),
			{ "data-cy": "toast-success" },
		)

		await Promise.all([
			fetchComments(commentsSearchParams),
			fetchInvoiceCertificate(id!),
		])

		setSubmitting(false)
		resetForm()
		closeModal()
	}

	const onSubmitComment = async (
		{ comment }: FormFields,
		{ resetForm }: FormikHelpers<FormFields>,
	) => {
		await submitComment({
			username: decodedToken?.name ?? "",
			relatedEntityId: id!,
			status: CommentStatus.New,
			type: CommentType.ProductAllocation,
			content: comment,
		})
		await fetchComments(commentsSearchParams)
		resetForm()
	}

	return (
		<ContainerBox withBottomMargin>
			<PageHeader
				className="mb-3 gap-4 lg:grid-cols-[auto_1fr]"
				backToPageUrl={getProductAllocationUrl()}
				backToPageLabel={t("backToPageLabel")}
				data-cy="page-title"
				wrapAside
				aside={
					<PageHeaderButtons
						transaction={invoiceCertificate}
						onOpenModal={openModal}
					/>
				}
			>
				{t("title")}
			</PageHeader>

			<Grid size={1} lgSize={2}>
				<DashboardCard data-cy="transactions-card">
					<CardHeader data-cy="title">
						{t("transactionTitle")}
					</CardHeader>
					<div className="grid gap-y-3">
						<Label data-cy="timestamp" title={t("timestamp")}>
							{formatTimestamp(
								invoiceCertificate.withdrawn.timestamp,
							)}
						</Label>
						<Label data-cy="customer" title={t("customer")}>
							{invoiceCertificate.customerName}
						</Label>
						<Label data-cy="product" title={t("product")}>
							{invoiceCertificate.declaredProduct}
						</Label>
						<Label data-cy="quantity" title={t("quantity")}>
							{formatNumberWithUnit(
								invoiceCertificate.gwpTotal.quantity,
								UnitType.Ton,
							)}
						</Label>
						<Label
							data-cy="poroductEmmisions"
							title={t("poroductEmmisions")}
						>
							{formatNumberWithUnit(
								invoiceCertificate.gwpCarbonCaptured.quantity,
								UnitType.Ton,
							)}
						</Label>
						<Label
							data-cy="allocatedSavings"
							title={t("allocatedSavings")}
						>
							{formatNumberWithUnit(
								invoiceCertificate.totalSavings.quantity,
								UnitType.KgCO2,
							)}
						</Label>
						<Label data-cy="savingsType" title={t("savingsType")}>
							{tCommon("biogenic")}:{" "}
							{formatNumberWithUnit(
								invoiceCertificate.biogenic.quantity,
								UnitType.KgCO2,
							)}
							<br />
							{tCommon("fossil")}:{" "}
							{formatNumberWithUnit(
								invoiceCertificate.fossil.quantity,
								UnitType.KgCO2,
							)}
						</Label>
					</div>
				</DashboardCard>
				<CommentBoxCard
					comments={comments}
					onSubmit={onSubmitComment}
				/>
			</Grid>
			<FlagAllocationModal
				flagScenario={isFlaggedFlow}
				isOpen={isOpen}
				onClose={closeModal}
				onFormSubmit={handleFlagAllocation}
			/>
		</ContainerBox>
	)
}
