import { TransferCertificate } from "@carbonbank/api"
import { LogoEvoZero } from "@carbonbank/assets"
import { CardHeader, DashboardCard, Label } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface DeclaredProductProps {
	certificate?: TransferCertificate
}
export const TransactionDeclaredProduct: React.FC<DeclaredProductProps> = ({
	certificate,
}) => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.declaredProduct",
	})
	return (
		<DashboardCard
			className="self-stretch"
			data-cy="transaction-declared-product"
		>
			<CardHeader>{t("title")}</CardHeader>
			<div className="flex flex-col gap-y-6">
				<div className="flex items-center gap-6">
					<Label title={t("declaredProduct")}>
						{certificate?.declaredProduct}
					</Label>
					<img
						src={LogoEvoZero}
						alt="evezero"
						className="w-[110px] h-[15px]"
					/>
				</div>
				<Label title={t("originCarbon")}>
					{certificate?.declaredProductOrigin}
				</Label>
			</div>
		</DashboardCard>
	)
}
