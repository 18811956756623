import { useTranslation } from "react-i18next"
import * as Yup from "yup"

export const useValidationSchema = () => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.formValidationModal",
	})

	return Yup.object().shape({
		totalAvailableToTransfer: Yup.number().required(),
		accumulatedQuantity: Yup.number().required(),
		contractNumber: Yup.string().required(),
		isPreviousContact: Yup.boolean().default(false),
		isManualCertificate: Yup.boolean().default(false),
		product: Yup.string().required(),

		epdFile: Yup.array().when(
			["cementAmount", "isManualCertificate", "totalAvailableToTransfer"],
			(
				[cementAmount, isManualCertificate, totalAvailableToTransfer],
				schema,
			) => {
				const isPartialTransfer =
					cementAmount < totalAvailableToTransfer
				if (isPartialTransfer && !isManualCertificate) {
					return (
						schema
							.of(
								Yup.mixed()
									.required(t("fileRequired"))
									.test(
										/* @TODO make it a yup validation method */
										t("unsupportedFileFormat"),
										value => {
											if (
												value &&
												value instanceof File
											) {
												return [
													"application/vnd.ms-excel",
													"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
													"text/csv",
												].includes(value.type)
											}
											return false
										},
									)
									.test(
										/* @TODO make it a yup validation method */
										t("fileSizeExceeded"),
										value => {
											const file = value as File
											return file
												? file.size <= 10 * 1024 * 1024
												: false
										},
									),
							)
							/* @TODO make it a yup validation method */
							.min(1, t("unsupportedFileFormat"))
					)
				}
				return schema.notRequired()
			},
		),
		cementAmount: Yup.number()
			.typeError(t("cementAmountNumber"))
			.required(t("cementAmountRequired"))
			.min(0, t("cementAmountMin"))
			.max(
				Yup.ref("$totalAvailableToTransfer"),
				t("cementAmountValidation"),
			),
		/* @TODO make it a yup method - requiredWhen */
		projectReference: Yup.string().when(
			["isPreviousContact"],

			(isPrev, schema) => {
				if (!isPrev[0]) {
					return schema.required(t("projectReferenceRequired"))
				}
				return schema
			},
		),

		street: Yup.string().when("isManualCertificate", (isManual, schema) => {
			if (isManual[0]) {
				return schema.required(t("streetRequired"))
			}
			return schema
		}),

		zipCode: Yup.string().when(
			"isManualCertificate",
			(isManual, schema) => {
				if (isManual[0]) {
					return schema.required(t("zipRequired"))
				}
				return schema
			},
		),
		companyName: Yup.string().when(
			"isManualCertificate",
			(isManual, schema) => {
				if (isManual[0]) {
					return schema.required(t("companyNameValidation"))
				}
				return schema
			},
		),
		city: Yup.string().when("isManualCertificate", (isManual, schema) => {
			if (isManual[0]) {
				return schema.required(t("cityRequired"))
			}
			return schema
		}),
		country: Yup.string().when(
			"isManualCertificate",
			(isManual, schema) => {
				if (isManual[0]) {
					return schema.required(t("countryRequired"))
				}
				return schema
			},
		),
		email: Yup.string()
			.email(t("emailFormatValidation"))
			.when("isManualCertificate", ([isManual], schema) => {
				return !isManual ? schema.required(t("emailRequired")) : schema
			}),
		confirmEmail: Yup.string()
			.email(t("emailFormatValidation"))
			.oneOf([Yup.ref("email")], t("emailMatchValidation"))
			.when(
				["isPreviousContact", "isManualCertificate"],
				([isPrev, isManual], schema) => {
					if (isPrev && isManual) {
						return schema
					}
					return schema.required(t("confirmEmailRequired"))
				},
			),
	})
}
