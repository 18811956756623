import { PermissionType, InvoiceCertificate } from "@carbonbank/api"
import { useAuthStore } from "@carbonbank/store"
import { getTransactionHashScanLink } from "@carbonbank/utils"
import {
	Button,
	DropdownItem,
	DropdownWithButton,
	icon,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface PageHeaderButtonsProps {
	transaction: InvoiceCertificate
	onOpenModal: () => void
}

export const PageHeaderButtons: React.FC<PageHeaderButtonsProps> = ({
	transaction: { isFlagged, withdrawn, invoiceLink, epdLink },
	onOpenModal,
}: PageHeaderButtonsProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "productAllocationDetails.components.pageHeaderButtons",
	})

	const [userPermissions] = useAuthStore(state => [state.userPermissions])

	const isActionBtnShouldBeDisplayed = userPermissions?.some(
		x => x.permissionType === PermissionType.ManageTransactions,
	)

	const invoiceTransactionLink = getTransactionHashScanLink(
		withdrawn.proof.identifier,
	)

	return (
		<div
			className="flex gap-3 flex-wrap justify-end"
			data-cy="action-buttons"
		>
			<DropdownWithButton
				className="block lg:hidden"
				label={t("dropdown.label")}
				variant="secondary"
				align="right"
				buttonProps={{
					iconPrefix: icon.mdiOpenInNew,
					iconSuffix: icon.mdiArrowDown,
					size: "large",
				}}
				onSelectItem={({ value }) =>
					window.open(value?.toString(), "_blank")
				}
			>
				<DropdownItem
					value={invoiceLink}
					title={t("dropdown.invoice")}
				/>
				<DropdownItem value={epdLink} title={t("dropdown.epd")} />
				<DropdownItem
					value={invoiceTransactionLink}
					title={t("dropdown.transaction")}
				/>
			</DropdownWithButton>
			<a
				href={invoiceLink}
				target="_blank"
				rel="noreferrer"
				className="hidden lg:block"
			>
				<Button
					size="large"
					variant="secondary"
					icon={icon.mdiOpenInNew}
				>
					{t("invoice")}
				</Button>
			</a>
			<a
				href={epdLink}
				target="_blank"
				rel="noreferrer"
				className="hidden lg:block"
			>
				<Button
					size="large"
					variant="secondary"
					icon={icon.mdiOpenInNew}
				>
					{t("epd")}
				</Button>
			</a>
			<a
				href={invoiceTransactionLink}
				target="_blank"
				rel="noreferrer"
				className="hidden lg:block"
			>
				<Button
					size="large"
					variant="secondary"
					icon={icon.mdiOpenInNew}
				>
					{t("transaction")}
				</Button>
			</a>
			{isActionBtnShouldBeDisplayed &&
				(isFlagged ? (
					<Button
						size="large"
						icon={icon.mdiCheck}
						onClick={onOpenModal}
					>
						{t("allocationResolved")}
					</Button>
				) : (
					<Button
						size="large"
						icon={icon.mdiFlagOutline}
						onClick={onOpenModal}
					>
						{t("allocationIncorrect")}
					</Button>
				))}
		</div>
	)
}
