﻿import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { formatNumber } from "@carbonbank/utils"
import { NodeProps, Text, View } from "@react-pdf/renderer"
import React from "react"

export const CarbonCaptureAttributesDescription = (
	props: {
		bioAmount: number
		fossilAmount: number
	} & NodeProps,
) => {
	return (
		<View style={{ ...styles.gap_2 }}>
			<View style={{ ...styles.flex_row }}>
				<Text
					style={{ ...styles.text_regular_small, ...styles.width_15 }}
				>
					*
				</Text>
				<Text style={{ ...styles.text_regular_small }}>
					GWP calculated in accordance with EN 15804 and EN 16908
				</Text>
			</View>
			<View style={{ ...styles.flex_row }}>
				<Text
					style={{ ...styles.text_regular_small, ...styles.width_15 }}
				>
					**
				</Text>
				<Text style={{ ...styles.text_regular_small }}>
					Carbon Savings are stemming from the European CCS plant in
					[Brevik, Norway]. The sum of carbon savings are made up of
					carbon reduction of biogenic carbon stored{" "}
					{formatNumber(props.bioAmount)} kg CO2-eq and fossil carbon
					stored {formatNumber(props.fossilAmount)} kg CO2-eq
				</Text>
			</View>
			<View style={{ ...styles.flex_row }}>
				<Text
					style={{ ...styles.text_regular_small, ...styles.width_15 }}
				>
					***
				</Text>
				<Text style={{ ...styles.text_regular_small }}>
					Carbon Footprint calculation prepared in accordance with
					Heidelberg Materials Carbon Savings Policy
					(https://www.evozero.com/Carbon_Savings_Policy)
				</Text>
			</View>
		</View>
	)
}
