import { CommentStatus } from "@carbonbank/api"
import { ModuleKeys } from "@carbonbank/pages/common"
import { icon } from "@sustainability/fundamental"
import { PillType } from "@sustainability/fundamental/dist/components/Pill/types"

type CommentPillText =
	| "New"
	| "In Validation"
	| "Rejected"
	| "Assured"
	| "Flagged"
	| "Resolved"

type CommentPill = {
	type: PillType
	text: ModuleKeys
	icon?: string
}

export const commentStatusToPillPropsMap: Record<
	CommentStatus,
	CommentPill | undefined
> = {
	[CommentStatus.None]: undefined,
	[CommentStatus.New]: {
		type: "neutral",
		text: "components.comment.status.new",
	},
	[CommentStatus.InValidation]: {
		type: "warning",
		text: "components.comment.status.inValidation",
	},
	[CommentStatus.Rejected]: {
		type: "negative",
		text: "components.comment.status.rejected",
	},
	[CommentStatus.Assured]: {
		type: "positive",
		text: "components.comment.status.assured",
	},
	[CommentStatus.Flagged]: {
		type: "warning",
		text: "components.comment.status.flagged",
		icon: icon.mdiFlag,
	},
	[CommentStatus.Resolved]: {
		type: "positive",
		text: "components.comment.status.resolved",
		icon: icon.mdiCheck,
	},
}

export const commentPillTextToCommentStatus: Record<
	CommentPillText | string,
	CommentStatus
> = {
	New: CommentStatus.New,
	"In Validation": CommentStatus.InValidation,
	Rejected: CommentStatus.Rejected,
	Assured: CommentStatus.Assured,
	Flagged: CommentStatus.Flagged,
	Resolved: CommentStatus.Resolved,
	default: CommentStatus.None,
}
