﻿import { TransferCertificate } from "@carbonbank/api"
import {
	AssuranceInfoSection,
	CarbonBankTransparencySection,
	CarbonCaptureAssuranceDescriptionSection,
	CarbonCaptureAttributes,
	CarbonCaptureDescriptionSection,
	CarbonSavingsVerificationSection,
	DateOfIssue,
	OwnerAddress,
	ProductInformationSection,
	TransferTraceabilitySection,
	TransferTransactionFooter,
	PageHeader,
} from "@carbonbank/pdf/certificates/transfer/components"
import { firstPageImage } from "@carbonbank/pdf/certificates/transfer/images/images.ts"
import {
	EvoZeroLogo,
	HcLogo,
	PageTitleLogo,
} from "@carbonbank/pdf/certificates/transfer/images/logos.tsx"
import { loadLexend } from "@carbonbank/pdf/fonts/lexend/lexend.ts"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import {
	formatTimestampAsFullDate,
	getTransactionHashScanLink,
} from "@carbonbank/utils"
import {
	Document,
	Image,
	NodeProps,
	Page,
	Text,
	View,
} from "@react-pdf/renderer"

export const CertificatePdfDocument = (
	props: {
		data: TransferCertificate
	} & NodeProps,
) => {
	const pageDpi = 96
	loadLexend()

	return (
		<Document>
			<Page size="A4" style={styles.page} dpi={pageDpi}>
				<View>
					<Image
						style={styles.background_image}
						src={firstPageImage}
					/>
				</View>
				<View style={styles.position_absolute}>
					<View style={styles.first_page_content}>
						<EvoZeroLogo />
						<PageTitleLogo />
						<DateOfIssue
							dateOfIssue={
								formatTimestampAsFullDate(
									props.data.transferred.timestamp,
								) ?? "@carbonTransferred.endDate"
							}
						/>
						<OwnerAddress
							postalCode={
								props.data.address.postalCode ??
								"@ownerAddress.postalCode"
							}
							customerName={props.data.customerName}
							city={
								props.data.address.city ?? "@ownerAddress.city"
							}
							country={"dwa"}
							street={"dwadwada"}
						/>
						<View>
							<Text style={styles.text_regular_small}>
								This certificate is in reference with the
								methodology developed by Heidelberg Materials
								and reviewed by DNV (reference to the document).
								The [EPD OR self-declaration] is prepared
								according to the standard EN 15804.
							</Text>
						</View>
					</View>
					<View style={styles.first_page_hc_logo}>
						<HcLogo />
					</View>
				</View>
			</Page>
			<Page
				size="A4"
				style={{ ...styles.page, ...styles.page_info }}
				dpi={pageDpi}
			>
				<PageHeader
					customerName={props.data.customerName}
					pageNumber={2}
				/>
				<ProductInformationSection
					declaredCementProduct={props.data.declaredProduct}
					originCarbonCapture={props.data.declaredProductOrigin}
					bundledProduct={props.data.bundledProduct}
					deliveredCementQuantity={props.data.cementQuantity}
					deliveredCementQuantityUom={"tonnes"}
					productionOrigin={props.data.bundledProductOrigin}
					reference={"EPD-HM-20240101-CEMII/A-LL32,5R"}
				/>
				<CarbonCaptureAttributes
					declaredProduct={props.data.declaredProduct}
					gwpCarbonCaptured={props.data.gwpCarbonCaptured.quantity}
					gwpGross={props.data.gwpTotal.quantity}
					fossilAmount={props.data.fossil.quantity}
					bioAmount={props.data.biogenic.quantity}
				/>
				<AssuranceInfoSection
					assuranceTransactionId={
						props.data.deposited.proof.identifier ??
						"@carbonDeposited.proof"
					}
					assuranceIssued={formatTimestampAsFullDate(
						props.data.deposited.timestamp,
					)}
					approver="DNV Business Assurance Germany GmbH"
					carbonSavings={props.data.totalSavings.quantity}
					customer={props.data.customerName}
					plant={props.data.declaredProductOrigin}
					firstDate={
						formatTimestampAsFullDate(
							props.data.steps[0].startDate,
						) ?? "@startDate"
					}
					lastDate={
						formatTimestampAsFullDate(
							props.data.steps[props.data.steps.length - 1]
								.endDate,
						) ?? "@endDate"
					}
				/>
				<TransferTransactionFooter
					transactionNumber={
						props.data.transferred.proof.identifier ??
						"@carbonTransferred.proofs"
					}
					verificationId={props.data.verificationNumber}
				/>
			</Page>
			<Page
				size="A4"
				dpi={pageDpi}
				style={{ ...styles.page, ...styles.page_info }}
			>
				<PageHeader
					customerName={props.data.customerName}
					pageNumber={3}
				/>
				<TransferTraceabilitySection
					steps={props.data.steps.map(x => ({
						title: x.name ?? "@step.stepTitle",
						duration:
							x.startDate === x.endDate
								? x.startDate ?? "@step.startDate"
								: `${formatTimestampAsFullDate(x.startDate)} - ${formatTimestampAsFullDate(x.endDate)}`,
					}))}
				/>
			</Page>
			<Page
				size="A4"
				dpi={pageDpi}
				style={{ ...styles.page, ...styles.page_info }}
			>
				<PageHeader
					customerName={props.data.customerName}
					pageNumber={4}
				/>
				<CarbonCaptureDescriptionSection />
				<CarbonCaptureAssuranceDescriptionSection />
			</Page>
			<Page
				size="A4"
				dpi={pageDpi}
				style={{ ...styles.page, ...styles.page_info }}
			>
				<PageHeader
					customerName={props.data.customerName}
					pageNumber={5}
				/>
				<CarbonBankTransparencySection />
				<CarbonSavingsVerificationSection
					verificationNumber={props.data.verificationNumber}
					transactionLink={getTransactionHashScanLink(
						props.data.transferred.proof.identifier,
					)}
					certificateTransactionNumber={
						props.data.transferred.proof.identifier ??
						"@data.transferTransactionId"
					}
				/>
			</Page>
		</Document>
	)
}
